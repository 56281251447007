a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: #F7B301;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.header-size {
    width: 100%;
    display: block;
}
.container{
    margin: 0 !important;
    padding: 0 !important;
}
