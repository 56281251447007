/*                                              */
/*                                              */
/*                CSS TEMPLATE                  */
/*                                              */
/*                                              */
/*                        */
/*      Basic Format      */
/*                        */
/*width: ;*/
/*height: ;*/
/*background: ;*/
/*color: ;*/
/*display: ;*/
/*align-items: ;*/
/*justify-content: ;*/
/*posistion: ;*/
/*left: ;*/
/*top: ;*/
/*right: ;*/
/*bottom: ;*/
/*z-index: ;*/
/*                        */
/*    Container Format    */
/*                        */
/*padding: ;*/
/*padding-bottom: ;*/
/*padding-left: ;*/
/*padding-top: ;*/
/*padding-right: ;*/
/*margin: ;*/
/*margin-bottom: ;*/
/*margin-left: ;*/
/*margin-top: ;*/
/*margin-right: ;*/
/*border: ;*/
/*border-bottom: ;*/
/*border-left: ;*/
/*border-top: ;*/
/*border-right: ;*/
/*border-radius: ;*/
/*border-bottom-left-radius: ;*/
/*border-top-left-radius: ;*/
/*border-top-right-radius: ;*/
/*border-bottom-right-radius: ;*/
/*overflow: ;*/
/*                        */
/*       Font Format       */
/*                        */
/*font-size: ;*/
/*font-family: ;*/
/*font-weight: ;*/
/*word-break: ;*/
/*text-align: ;*/
/*text-decoration-line: ;*/
/* Provide sufficient contrast against white background */
body {
    background-color: #15405c;
    overflow: hidden;
}

div {
    scrollbar-width: thin;
    scrollbar-color: #b89961 #15405c;
}

a {
    color: #b89961;
    background-color: #15405c;
}

code {
    color: #E01A76;
}

svg {
    cursor: pointer;
    color: #b89961;
}

h1, h2, h3, h4, h5, h6 {
    color: #e7ddca;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.side-menu-list {
    margin: 0px !important;
    padding: 10px !important;
    background-color: #15405c !important;
    color: #c2a877;
}

.side-menu-main-container {
    margin: 12px !important;
    margin-top: -14px !important;
    padding: 10px !important;
    background-color: #15405c !important;
    border-right: 1px solid #c2a877;
    height: 96vh;
    overflow: auto;
}

.side-menu-main-container-sizeless {
    margin-left: -22px !important;
    background-color: #15405c !important;
    overflow: auto;
    border: 2px solid #c2a877;
}


.side-menu-list-container {
    margin: 12px !important;
    margin-top: 2px !important;
    padding: 10px !important;
    background-color: #15405c !important;
    border-left: 1px solid #c2a877;
}

.side-menu-list-item {
    margin: 0px !important;
    padding: 0px !important;
    border: none;
    background-color: #15405c;
    color: #d7c7a7;
}

.no-margin-container {
    margin: 0px !important;
    padding: 0px !important;
    background-color: #15405c;
}

.nav-bar-border {
    border-bottom: 1px solid #c2a877;
}


.overflow-enabled {
    overflow-y: scroll;
    height: 90vh;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background: #15405c;
}

::-webkit-scrollbar-thumb {
    background: #c2a877;
}

.mciDiv::-webkit-scrollbar {
    width: 3px;
}

.mciDiv::-webkit-scrollbar-track {
    background: #D5E5F5;
}

.mciDiv::-webkit-scrollbar-thumb {
    background: #D5E5F5;
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #c2a877;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
}

.markerNoBorder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #000;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
}

textarea {
    width: 100%;
    background-color: #d7c7a7;
}

.tab {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #d7c7a7 !important;
    color: #15405c;
    border: 3px solid #4C4A42;
    border-bottom: 5px solid #4C4A42;
    margin: 0px !important;
}

.tab-active {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #b89961 !important;
    color: #15405c;
    border: 3px solid #d7c7a7;
    border-bottom: 5px solid #4C4A42;
}

.tab-alt {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #4C4A42 !important;
    color: #d7c7a7;
    border: 3px solid #d7c7a7;
}

.resize-handle {
    position: absolute;
    top: 0;
    right: -3px;
    bottom: 0;
    background: black;
    opacity: 0;
    width: 6px;
    cursor: col-resize;
}

.fixedTable {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    /*border-top: 1px solid #d7c7a7;*/
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.fixedTableHeaderFirst {
    position: sticky;
    left: 0;
    top: 0;
    font-size: 16px;
    background: #4C4A42;
    border-top: 1px solid #d7c7a7;
    border-bottom: 1px solid #d7c7a7;
    border-right: 1px solid #d7c7a7;
    border-left: 1px solid #d7c7a7;
    z-index: 4;
}

.fixedTableHeaderFirstNoStick {
    font-size: 18px;
    background: #4C4A42;
    border: 1px solid #d7c7a7;
    z-index: 4;
    padding-left: 4px;
}

.fixedTableHeader {
    position: sticky;
    top: 0;
    font-size: 16px;
    background: #4C4A42;
    border-top: 1px solid #d7c7a7;
    border-right: 1px solid #d7c7a7;
    border-bottom: 1px solid #d7c7a7;
    padding-left: 4px;
}

.fixedTableHeaderHidden {
    border: 0px !important;
    box-shadow: none !important;
    background: #15405c !important;
    border-right: 1px solid #d7c7a7 !important;
    border-left: 1px solid #d7c7a7 !important;
    padding-left: 4px;
}

.fixedTableHeaderNoStick {
    border: 0px;
    background-color: #4C4A42;
    z-index: 1;
    padding-left: 4px;
}

.fixedTableCell {
    border: 0px;
    border-right: 1px solid #d7c7a7;
    border-bottom: 1px solid #d7c7a7;
    padding-left: 4px;
    font-size: 14px;
    background-color: #15405c;
}

.fixedTableCellFirst {
    border: 0px;
    background-color: #15405c;
    position: sticky;
    left: 0;
    z-index: 1;
    padding-left: 4px;
    border-left: 1px solid #d7c7a7;
    border-right: 1px solid #d7c7a7;
    border-bottom: 1px solid #d7c7a7;
    font-size: 14px;
}

.fixedTableCellHidden {
    border: 0px !important;
    border-right: 1px solid #d7c7a7 !important;
    border-left: 1px solid #d7c7a7 !important;
    box-shadow: 0px !important;
    background: #15405c !important;
    padding-left: 4px;
}

.fixedTableCellFirstNoStick {
    border: 0px;
    border-left: 1px solid #d7c7a7;
    border-right: 1px solid #d7c7a7;
    border-bottom: 1px solid #d7c7a7;
    background-color: #4C4A42;
    z-index: 0;
    padding-left: 4px;
}

.fixedTableCellTop {
    border: 0px;
    border-top: 2px solid #d7c7a7;
    border-right: 2px solid #d7c7a7;
    border-bottom: 2px solid #d7c7a7;
    padding-left: 4px;
}

.fixedTableHeaderSideFirst {
    position: sticky;
    top: 0;
    font-size: 18px;
    background: #4C4A42;
    border: 2px solid #d7c7a7;
    padding-left: 4px;
}

.fixedTableHeaderSide {
    position: sticky;
    top: 0;
    font-size: 18px;
    background: #4C4A42;
    border: 2px solid #d7c7a7;
    border-top: 0px;
    border-bottom: 2px solid #d7c7a7;
    border-right: 2px solid #d7c7a7;
    padding-left: 4px;
}

.center-table-contents {
    text-align: center;
}

.button-link {
    padding: 0px;
    margin: 0px;
    background-color: #15405c;
    color: #b89961;
}

.react-date-picker__inputGroup,
.react-date-picker__inputGroup__input {
    color: #d7c7a7;
}

.react-date-picker__wrapper {
    border: 0px !important;
}

.react-date-picker__calendar,
.react-date-picker__calendar--open {
    z-index: 35;
}

.rv-discrete-color-legend-item__title {
    color: #e7ddca;
    font-size: 12px;
}

.modal-content {
    background-color: #d7c7a7;
    color: #15405c;
}

.modal-title {
    color: #15405c;
}

.dropdownItem {
    background-color: #b89961;
}

.dropdown-item {
    color: #15405c;
}

    .dropdown-item:hover {
        background-color: #d7c7a7;
    }

.standard-input {
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    height: 4vh;
    color: #15405c;
    background-color: #d7c7a7;
}

@media (prefers-color-scheme: light){
    .standard-input-supply {
        width: 100%;
        border-radius: 10px;
        padding-left: 10px;
        height: 4vh;
        color: #15405c;
        background-color: #d7c7a7;
    }

    .supply-table-header-first {
        background-color: #1F2B37;
        border: 1px solid #b89961;
    }

    .supply-table-header {
        background-color: #1F2B37;
        border-top: 1px solid #b89961;
        border-right: 1px solid #b89961;
        border-bottom: 1px solid #b89961;
    }

    .supply-table-cell {
        background-color: #1F2B37;
        border-right: 1px solid #b89961;
        border-bottom: 1px solid #b89961;
    }

    .supply-table-cell-first {
        background-color: #1F2B37;
        border-left: 1px solid #b89961;
        border-right: 1px solid #b89961;
        border-bottom: 1px solid #b89961;
    }
}

@media (prefers-color-scheme: dark){
    .standard-input-supply {
        width: 100%;
        border-radius: 10px;
        padding-left: 10px;
        height: 4vh;
        color: #15405c;
        background-color: #d7c7a7;
    }

    .supply-table-header-first {
        background-color: #1F2B37;
        border: 1px solid #b89961;
    }

    .supply-table-header {
        background-color: #1F2B37;
        border-top: 1px solid #b89961;
        border-right: 1px solid #b89961;
        border-bottom: 1px solid #b89961;
    }

    .supply-table-cell {
        background-color: #1F2B37;
        border-right: 1px solid #b89961;
        border-bottom: 1px solid #b89961;
    }

    .supply-table-cell-first {
        background-color: #1F2B37;
        border-left: 1px solid #b89961;
        border-right: 1px solid #b89961;
        border-bottom: 1px solid #b89961;
    }
}

.standard-input:disabled,
.standard-input[disabled]{
    background-color: #ccc;
}

label {
    font-size: 18px;
    margin-left: 4px;
    margin-top: 8px;
}

.scrollable-table-container {
    overflow: auto;
    min-height: 82vh;
    max-height: 82vh;
}


.fixedTableCellFirstLink {
    cursor: pointer;
    background-color: #4C4A42;
    border: 0px;
    border-left: 2px solid #d7c7a7;
    border-right: 2px solid #d7c7a7;
    border-bottom: 2px solid #d7c7a7;
    background-color: #4C4A42;
    position: sticky;
    left: 0;
}

    .fixedTableCellFirstLink:hover {
        color: #b89961;
        text-decoration: underline;
    }


.entity-node {
    height: 90px;
    font-size: 12px;
    width: 150px;
    background-color: #b89961;
    color: #15405c;
}

@media print {
    @page {
        size: auto;
    }

    .no-print {
        display: none !important;
    }

    .force-width {
        width: 100% !important;
    }

    .force-map-height {
        height: 117vh !important;
    }

    .force-background-height {
        height: 100vh !important;
    }

    .hidden-print {
        display: block !important;
    }

    .force-background {
        background-color: #15405c;
    }

    .borderless {
        border: 0px !important;
    }

    .border-print {
        border: 3px solid #d7c7a7;
    }

    .print-overflow {
        overflow: visible !important;
    }

    body {
        background-color: #15405c;
    }

    .mciForceBackground {
        width: 104% !important;
        background: #FFFFFF !important;
        margin-left: -2%;
        margin-right: 2%;
        margin-top: -2%;
        margin-bottom: 0%;
        padding-top: 2%;
        border: 0px !important;
    }

    .mciReportingPrint {
        margin-left: 15%;
        margin-top: 20%;
    }

    .hiddenBorder {
        border: 0px !important;
    }
}


.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

    .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #b89961;
        border-color: #b89961 transparent #b89961 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

.lds-dual-ring-blue {
    display: inline-block;
    width: 80px;
    height: 80px;
}

    .lds-dual-ring-blue:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #15405c;
        border-color: #15405c transparent #15405c transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mci-lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

    .mci-lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #1F2B37;
        border-color: #1F2B37 transparent #1F2B37 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

@keyframes mci-lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


input {
    background-color: #d7c7a7;
}

button {
    background-color: #15405c;
    color: #d7c7a7;
}

label {
    color: #d7c7a7;
}

select {
    background-color: #d7c7a7;
}

hr {
    background-color: #d7c7a7;
    height: 5px;
}

table {
    border-collapse: collapse;
    border: 0px;
    width: 100%;
}

th {
    /*border: 1px solid #d7c7a7;*/
    width: 33%;
    color: #d7c7a7;
}

    th.large {
        width: 45%;
    }

    th.med {
        width: 90%;
    }

    th.small {
        width: 25%;
    }

    th.fortyfive {
        width: 45%;
    }

    th.forty {
        width: 40%;
    }

    th.thirtyfive {
        width: 35%;
    }

    th.third {
        width: 33.3334%;
    }

    th.thirty {
        width: 30%;
    }

    th.twenty-seven {
        width: 27.5%;
    }

    th.twenty-five {
        width: 25%;
    }

    th.twenty {
        width: 20%;
    }

    th.fifteen {
        width: 15%;
    }

    th.ten {
        width: 10%;
    }

    th.seven {
        width: 7.5%;
    }

    th.icon {
        width: 5%;
    }

tr {
    width: 100%;
}

td {
    /*border: 1px solid #d7c7a7;*/
    color: #d7c7a7;
    word-break: break-word;
}

p {
    color: #d7c7a7;
}

a.button-look {
    background-color: #15405c;
    color: #d7c7a7;
}

.global-alert {
    position: absolute;
    margin-left: 50%;
    margin-top: 2px;
    background-color: #d7c7a7;
    display: none;
    width: 300px;
    height: 48px;
    border-radius: 1em;
}

.alert-text {
    height: 100%;
    margin: 0px;
    padding: 5px 0;
    color: #15405c;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.exam-draggable {
    border: 2px solid #b89961;
    text-align: center;
    vertical-align: central;
    margin: 10px;
    background-color: #b89961;
    color: #15405c;
}

.exam-img {
    width: 200px;
    height: 200px;
    border: 2px solid #1B5C9E;
    background-color: #ffffff;
    margin-top: 0px;
}

.exam-question-input {
    background-color: #15405c;
    color: #b89961;
    margin: 0px;
    margin-bottom: 3px;
    width: 100%;
    border: 0px;
}

    .exam-question-input::placeholder {
        color: #eeeeee;
    }

.mciTableHeaderFirst {
    position: sticky;
    left: 0;
    top: 0;
    font-size: 18px;
    z-index: 4;
    border: 2px solid #D5E5F5;
    background-color: #78A1CA;
    color: #1F2B37;
}

.mciTableHeader {
    position: sticky;
    top: 0;
    font-size: 18px;
    background: #78A1CA;
    border: 0px;
    border-top: 2px solid #D5E5F5;
    border-bottom: 2px solid #D5E5F5;
    border-right: 2px solid #D5E5F5;
    color: #1F2B37;
}

.mciFixedTableCellFirst {
    border: 0px;
    border-left: 2px solid #D5E5F5;
    border-right: 2px solid #D5E5F5;
    border-bottom: 2px solid #D5E5F5;
    background-color: #78A1CA;
    position: sticky;
    left: 0;
    z-index: 0;
    color: #1F2B37;
    font-weight: bold;
}

.mciFixedTableCell {
    border: 0px;
    border-right: 2px solid #D5E5F5;
    border-bottom: 2px solid #D5E5F5;
    color: #D5E5F5;
    font-weight: bold;
}


.mci-standard-input {
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    height: 4vh;
    color: #1F2B37;
    background-color: #D5E5F5;
}

.mci-header {
    color: #78A1CA;
}

.mci-label {
    color: #D5E5F5;
}

.mci-logo-img {
    margin-left: 45%;
    /*background-color: #ffffff;*/
}

.required {
    border: 3px solid red;
}

.mciLoginPageContainer {
    height: 100vh;
    width: 100%;
    background-color: #1F2B37;
}

.mciLoginContainer {
    margin-top: 22vh;
    margin-left: 35%;
    width: 30%;
    height: 35vh;
    background-color: #1F2B37;
    border: 2px solid #78A1CA;
    border-radius: 3em 3em;
}

.mciStudentProfileImage {
    margin-left: 45em;
    margin-top: 1em;
    width: 30em;
    height: 30em;
    border: 2px solid #1F2B37;
    cursor: pointer;
    background-color: #fff;
}

::placeholder {
    color: #4C4A42;
}

.fa-icon {
    width: 1em;
    height: 1em;
}

.required-header {
    color: #FD6F51;
}

input[required] {
    border: 2px solid #FD6F51;
}

    input[required]:focus {
        border: 2px solid #FD6F51;
        outline: none;
    }

textarea[required] {
    border: 1px solid #FD6F51;
}

    textarea[required]:focus {
        border: 2px solid #FD6F51;
        outline: none;
    }

@media only screen and (max-width: 1280px) {
    .mci-logo-img {
        margin-left: 25%;
    }

    .mciLoginContainer {
        width: 70%;
        margin-top: 18vh;
        margin-left: 15%;
        background-color: #1F2B37;
        border: 2px solid #78A1CA;
        height: 40vh;
    }

    .mciStudentProfileImage {
        margin-left: 25%;
        width: 50%;
    }

    .fa-icon {
        width: 2em;
        height: 2em;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* Light mode Ticketing */
@media (prefers-color-scheme: light) {
    .ticket-submission-form {
        background-color: #D9D2B6;
        height: 100vh;
        overflow: auto;
    }

        .ticket-submission-form::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

    .ticket-text-heading {
        color: #4E4F50;
        font-size: 30px;
        text-align: center;
    }

    .ticket-text-title {
        color: #4E4F50;
        font-size: 24px;
        margin-top: 13px;
        background: #D9D2B6;
    }

    .ticket-text {
        color: #4E4F50;
        font-size: 16px;
        margin-top: 13px;
        background: #D9D2B6;
    }

    .ticket-standard-input {
        border-radius: 5px;
        background-color: #fffefc;
        color: #4E4F50;
        width: 100%;
        padding-left: 5px;
        margin-bottom: 5px;
    }

    .ticket-submit-button {
        border-radius: 5px;
        background-color: #1F2B37;
        color: #E2DED0;
        width: 100%;
        padding-left: 5px;
    }

    ::placeholder {
        color: #4E4F50;
    }

    .required-header {
        color: #aa0000 !important;
    }

    input[required] {
        border: 1px solid #aa0000;
    }

        input[required]:focus {
            border: 2px solid #aa0000;
            outline: none;
        }

    textarea[required] {
        border: 1px solid #aa0000;
    }

        textarea[required]:focus {
            border: 2px solid #aa0000;
            outline: none;
        }

    .ticketTabSelected {
        background-color: #1F2B37;
        color: #E2DED0;
        padding-bottom: 5px;
        padding-top: 5px;
        border: 3px solid #d7c7a7;
        border-bottom: 5px solid #d7c7a7;
    }

    .ticketTab {
        background-color: #E2DED0;
        color: #647C90;
        padding-bottom: 5px;
        padding-top: 5px;
        border: 3px solid #d7c7a7;
    }
}

/* Dark mode Ticketing */
@media (prefers-color-scheme: dark) {
    .ticket-submission-form {
        background-color: #4E4F50;
        height: 100vh;
        overflow: auto;
    }

        .ticket-submission-form::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }

    .ticket-text-heading {
        color: #E2DED0;
        font-size: 30px;
        text-align: center;
    }

    .ticket-text {
        color: #E2DED0;
        font-size: 16px;
        margin-top: 13px;
        background: #4E4F50;
    }

    .ticket-standard-input {
        border-radius: 5px;
        background-color: #647C90;
        color: #E2DED0;
        width: 100%;
        padding-left: 5px;
        margin-bottom: 5px;
    }

    .ticket-submit-button {
        border-radius: 5px;
        background-color: #647C90;
        color: #E2DED0;
        width: 100%;
        padding-left: 5px;
    }

    .ticket-standard-input::placeholder {
        color: #E2DED0;
    }

    .required-header {
        color: #FD6F51;
    }

    input[required] {
        border: 2px solid #FD6F51;
    }

        input[required]:focus {
            border: 2px solid #FD6F51;
            outline: none;
        }

    textarea[required] {
        border: 1px solid #FD6F51;
    }

        textarea[required]:focus {
            border: 2px solid #FD6F51;
            outline: none;
        }

    .ticketTabSelected {
        background-color: #E2DED0;
        color: #647C90;
        padding-bottom: 5px;
        padding-top: 5px;
        border: 3px solid #d7c7a7;
        border-bottom: 5px solid #d7c7a7;
    }

    .ticketTab {
        background-color: #647C90;
        color: #E2DED0;
        padding-bottom: 5px;
        padding-top: 5px;
        border: 3px solid #d7c7a7;
    }
}


.anon-submission-form {
    background-color: #4E4F50;
    height: 100vh;
    overflow: auto;
}

    .anon-submission-form::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.anon-text-heading {
    color: #E2DED0;
    font-size: 30px;
    text-align: center;
}

.anon-text {
    color: #E2DED0;
    font-size: 16px;
    margin-top: 13px;
    background: #4E4F50;
}

.anon-standard-input {
    border-radius: 5px;
    background-color: #647C90;
    color: #E2DED0;
    width: 100%;
    padding-left: 5px;
    margin-bottom: 5px;
}

.anon-submit-button {
    border-radius: 5px;
    background-color: #647C90;
    color: #E2DED0;
    width: 100%;
    padding-left: 5px;
}

.anon-standard-input::placeholder {
    color: #E2DED0;
}

.required-header {
    color: #FD6F51;
}

input[required] {
    border: 2px solid #FD6F51;
}

    input[required]:focus {
        border: 2px solid #FD6F51;
        outline: none;
    }

textarea[required] {
    border: 1px solid #FD6F51;
}

    textarea[required]:focus {
        border: 2px solid #FD6F51;
        outline: none;
    }

.anonTabSelected {
    background-color: #E2DED0;
    color: #647C90;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 3px solid #d7c7a7;
    border-bottom: 5px solid #d7c7a7;
}

.anonTab {
    background-color: #647C90;
    color: #E2DED0;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 3px solid #d7c7a7;
}

.tooltip {

}

.tooltip.show{
    opacity: 1;
}



/* Tooltip container */
.calendarToolTip {
    position: relative;
    display: inline;
}

    /* Tooltip text */
    .calendarToolTip .calendarToolTipText {
        visibility: hidden;
        width: fit-content;
        background-color: #ccc;
        border: 2px solid #1F2B37;
        color: #1F2B37;
        text-align: center;
        font-size: 32px;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .calendarToolTip:hover .calendarToolTipText {
        visibility: visible;
    }



.mciStudentMainFormat {
    height: 101vh;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2%;
    overflow: auto;
    margin-top: -1vh;
}

    .mciStudentMainFormat::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.mciStudentNavMenu {
    height: 96%;
    background: #242f3b;
    position: relative;
    padding-bottom: 2%;
    padding-top: 2%;
    border-radius: 50px;
    overflow: auto;
}

    .mciStudentNavMenu::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.mciStudentProfileContainer {
    height: 96%;
    background: #d5d5d5;
    border-radius: 50px;
    overflow: auto;
}

    .mciStudentProfileContainer::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.mciStudentModuleContainer {
    height: 45vh;
    margin: 2%;
    overflow: auto;
}

    .mciStudentModuleContainer::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.mciStudentExamFormMainFormat {
    height: 101vh;
    background: #242f3b;
    padding: 5%;
}

    .mciStudentExamFormMainFormat::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.mciStudentExamFormMainContainer {
    height: 100%;
    background: #d5d5d5;
    padding: 4%;
    border-radius: 25px;
    overflow: auto;
}

    .mciStudentExamFormMainContainer::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.entityNewRequestFormContainer {
    height: 75vh;
    border-bottom: 8vh;
    overflow: auto;
}

    .entityNewRequestFormContainer::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

.standardInput {
    width: 100%;
    height: 5vh;
    background-color: #d7c7a7;
    color: #15405c;
    padding-left: 10px;
    border-radius: 10px;
}

.tabActive {
    background-color: #b89961 !important;
    color: #15405c;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 3px solid #d7c7a7;
    border-bottom: 5px solid #4C4A42;
}

.tabAlt {
    background-color: #4C4A42 !important;
    color: #d7c7a7;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 3px solid #d7c7a7;
}

/*Tech Projects*/
.tech-project-main {
    height: 90vh;
    padding-bottom: 5vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

    .tech-project-main::-webkit-scrollbar {
        display: none;
    }

.tech-project-tab {
    background-color: #d7c7a7 !important;
    color: #15405c;
    height: 4vh;
    border: 2px solid #15405c;
}

.tech-project-tab-active {
    color: #15405c;
    background-color: #b89961 !important;
    height: 4vh;
    border: 2px solid #d7c7a7;
}

.progress-bar {
    width: 100%;
    height: 2vh;
    border: 2px solid white;
    border-radius: 0px 10px 10px 0px;
    background-image: linear-gradient(to right, #333, #aaa);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
}

    .progress-bar[value]::-moz-progress-bar {
        background-image: linear-gradient(to right, red 0%, yellow 50%, #006622 100%);
        animation: fadeIn 1s east normal;
    }

    .progress-bar::-webkit-progress-bar {
        background-image: linear-gradient(to right, #333, #aaa);
    }

    .progress-bar::-webkit-progress-value {
        background-image: linear-gradient(to right, red 0%, orange 20%, yellow 40%, #006622 80%);
    }


.over-under-under {
    width: 100%;
    height: 2vh;
    border: 2px solid white;
    border-right: 2px dashed black;
    border-radius: 10px 0px 0px 10px;
    background-color: #cc0000;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
}

    .over-under-under::-moz-progress-bar {
        background: #ccc;
    }

    .over-under-under::-webkit-progress-bar {
        background-image: linear-gradient(to right, #cc0000, #cc0000);
    }

    .over-under-under::-webkit-progress-value {
        background-color: #ccc;
    }


.over-under-over {
    width: 100%;
    height: 2vh;
    border: 2px solid white;
    border-radius: 0px 10px 10px 0px;
    border-left: 0px;
    background-color: #ccc;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25) inset;
}

    .over-under-over::-moz-progress-bar {
        background: #006622;
    }

    .over-under-over::-webkit-progress-bar {
        background-image: linear-gradient(to right, #ccc, #ccc);
    }

    .over-under-over::-webkit-progress-value {
        background-image: linear-gradient(to right, #006622, #006622);
    }
