

.mci-student-image {
    width: 69%;
    height: 69%;
    border-radius: 50%;
    min-height: 10vh;
    max-height: 25vh;
    min-width: 5vw;
    max-width: 20vw;
}

.mci-student-settings-label {
    color: #1F2B37;
    font-size: 14px;
    font-family: garmund;
    font-weight: lighter;
    text-align: center;
}